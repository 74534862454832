




































































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {Prop, Ref} from 'vue-property-decorator';
import {RepoPage} from '@/models';
import {HrBoardArticle, HrBoardArticleResponse} from '@/model-hr';
import AXIOS from '../services/axios-wrapper';
import moment from 'moment';
import {BvTableCtxObject} from 'bootstrap-vue/src/components/table';

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
  },
})
export default class HrBoardArticleList extends GeneralUtil {
  @Prop(Number) readonly boardInfoId!: number;
  @Prop(String) readonly boardArticleReadLink!: string;

  boardFields = [
    {
      key: 'id',
      label: 'No',
    },
    {
      key: 'jobType',
      label: '구분',
    },
    {
      key: 'title',
      label: '모집부문',
    },
    {
      key: 'period',
      label: '접수기간',
    },
    {
      key: 'articleStatus',
      label: '상태',
    },
    {
      key: 'action',
      label: '지원확인',
    },
    // {
    //   key: 'chgDate',
    //   label: '날짜',
    //   formatter: (value: string) => moment(value).format('YYYY-MM-DD'),
    // },
  ];

  dto: HrBoardArticle = {
    title: '',
    memo: '',
  };

  page: RepoPage = {
    number: 1,
    size: 10,
    totalElements: 10,
    totalPages: 1,
  };
  mobileList: HrBoardArticle[] = [];

  rowClicked(item: HrBoardArticle) {
    this.$router.push(`${this.boardArticleReadLink}${item.id}`);
  }
  showRequestPage(item: HrBoardArticle) {
    this.$router.push(`/p/HrRequest/${item.id}`);
    //this.$router.push(`${this.boardArticleReadLink}${item.id}`);
  }

  async itemsProvider(ctx: BvTableCtxObject) {
    try {
      const req_uri = `/HrBoardArticleRepo?projection=HrBoardArticleSummary&page=${this.page.number - 1}&size=${
        this.page.size
      }&sort=id,desc&del=0&isView=1`;

      const response = await AXIOS.get<HrBoardArticleResponse>(req_uri);
      this.page = response.data.page;
      this.page.number++; // API 기준은 0 base
      this.mobileList = response.data._embedded.hrBoardArticles;
      return response.data._embedded.hrBoardArticles;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
