<template>
  <div class="breadContainer">
    <div class="crumb">
      <div class="crumbItem topLink">
        {{ Breadcrumb_link.toplink }}
      </div>
      <div class="crumbItem middleLink">></div>
      <div class="crumbItem middleLink">
        {{ Breadcrumb_link.prevlink }}
      </div>
      <div class="crumbItem middleLink">></div>
      <div class="crumbItem presentLink">
        {{ Breadcrumb_link.present }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    Breadcrumb_link: {
      linkitem: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
//.breadcrumb-item + .breadcrumb-item::before {
//  content: ' ';
//}

.topLink {
  font-weight: 600;
  color: gray;
}

.middleLink {
  font-weight: 600;
  color: gray;
}

.presentLink {
  font-weight: 900;
  color: #f5a100;
}

@media (max-width: 992px) {
  .breadContainer {
    display: none;
  }
}
@media (min-width: 992px) {
  .breadContainer {
    font-size: 1rem;
    //margin-top: 7.5rem;
    padding-top: 11rem;
    background-color: white;
    border-bottom: 1px solid #ebebeb;

    .crumb {
      display: flex;
      flex-direction: row;

      font-size: 1rem;
      text-align: center;
      background-color: white;

      padding-left: 1rem;
      line-height: 3rem;

      .crumbItem {
        vertical-align: middle;
        margin-right: 0.5em;
        font-size: 1em;
      }
    }
  }
}
@media (min-width: 1280px) {
  .breadContainer {
    font-size: 1rem;
    //margin-top: 7.5rem;
    padding-top: 11rem;
    background-color: white;
    border-bottom: 1px solid #ebebeb;

    .crumb {
      display: flex;
      flex-direction: row;

      font-size: 1rem;
      text-align: center;
      max-width: 1280px;
      background-color: white;

      line-height: 3rem;
      margin-left: auto;
      margin-right: auto;

      .crumbItem {
        vertical-align: middle;
        margin-right: 0.5em;
        font-size: 1em;
      }
    }
  }
}
</style>
